import React, { useState, useEffect, useCallback } from 'react';
import { App, Page,  } from 'konsta/react';

import Character from './components/Character';
import Footer from './components/Footer';
import EnergyJar from './components/EnergyJar';
import MoodProgressBar from './components/MoodProgressBar';
import Header from './components/Header';
import themes from './components/themes';
import Tasks from './components/Tasks'; 
import WebApp, { initTelegramWebApp, getTelegramUser } from './utils/telegramWebApp';

// Add these near the top of the file, after other imports
const LeaderboardPage = ({ isDarkMode }) => <div>Leaderboard Page</div>;
const PowerUpsPage = ({ isDarkMode }) => <div>Power Ups Page</div>;

function MyApp() {
  const [energy, setEnergy] = useState(500);
  const [points, setPoints] = useState(0);
  const [mood, setMood] = useState('sad');
  const [level, setLevel] = useState(1);
  const [interactionCount, setInteractionCount] = useState(0);
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());
  const [secondsUntilDecay, setSecondsUntilDecay] = useState(120);
  const [isInteracting, setIsInteracting] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [telegramUser, setTelegramUser] = useState(null);
  const [questMultiplier, setQuestMultiplier] = useState(1);
  const [questCooldown, setQuestCooldown] = useState(0);
  const [activePage, setActivePage] = useState('home');
  const [xFollowClaimed, setXFollowClaimed] = useState(false);
  const [youtubeClaimed, setYoutubeClaimed] = useState(false);

  useEffect(() => {
    initTelegramWebApp();
    const user = getTelegramUser();
    if (user) {
      setTelegramUser(user);
    }
    
    const isLargeScreen = window.matchMedia('(min-width: 1024px)').matches;
    
    if (isLargeScreen) {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(WebApp.colorScheme === 'dark');
    }

    WebApp.onEvent('themeChanged', () => {
      setIsDarkMode(WebApp.colorScheme === 'dark');
    });
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Date.now();
      const timeSinceLastInteraction = (now - lastInteractionTime) / 1000;
      
      if (timeSinceLastInteraction >= 120) {
        setMood(prevMood => {
          if (prevMood === 'happy') return 'neutral';
          if (prevMood === 'neutral') return 'sad';
          return prevMood;
        });
        setLastInteractionTime(now);
      } else {
        setSecondsUntilDecay(120 - Math.floor(timeSinceLastInteraction));
      }

      if (questCooldown > 0) {
        setQuestCooldown(prevCooldown => Math.max(0, prevCooldown - 1));
      }

      setEnergy(prevEnergy => Math.min(500, prevEnergy + 1));
    }, 1000);

    return () => clearInterval(timer);
  }, [lastInteractionTime, questCooldown]);

  const handleInteraction = useCallback((type) => {
    setIsInteracting(true);
    setTimeout(() => setIsInteracting(false), 500);

    let pointsEarned = 10 * questMultiplier;
    let energyCost = 10;

    if (type === 'quest2x') {
      setQuestMultiplier(2);
      setQuestCooldown(300);
      return;
    } else if (type === 'meditate') {
      setEnergy(prevEnergy => Math.min(500, prevEnergy + 50));
      return;
    } else if (type === 'evolve') {
      if (points >= 10000) {
        setPoints(prevPoints => prevPoints - 10000);
        setLevel(prevLevel => prevLevel + 1);
      }
      return;
    }

    if (energy >= energyCost) {
      setEnergy(prevEnergy => prevEnergy - energyCost);
      setPoints(prevPoints => prevPoints + pointsEarned);
      setInteractionCount(prevCount => prevCount + 1);
      setLastInteractionTime(Date.now());

      if (interactionCount % 10 === 9) {
        setMood(prevMood => {
          if (prevMood === 'sad') return 'neutral';
          if (prevMood === 'neutral') return 'happy';
          return prevMood;
        });
      }
    }
  }, [energy, interactionCount, points, questMultiplier]);

  const handleXFollowClaim = () => {
    if (!xFollowClaimed) {
      setPoints(prevPoints => prevPoints + 500);
      setXFollowClaimed(true);
    }
  };

  const handleYouTubeClaim = () => {
    if (!youtubeClaimed) {
      setPoints(prevPoints => prevPoints + 500);
      setYoutubeClaimed(true);
    }
  };

  const renderActivePage = () => {
    switch (activePage) {
      case 'tasks':
        return (
          <Tasks 
            isDarkMode={isDarkMode} 
            onClaimX={handleXFollowClaim} 
            xClaimed={xFollowClaimed}
            onClaimYouTube={handleYouTubeClaim}
            youtubeClaimed={youtubeClaimed}
          />
        );
      case 'leaderboard':
        return <LeaderboardPage isDarkMode={isDarkMode} />;
      case 'powerups':
        return <PowerUpsPage isDarkMode={isDarkMode} />;
      case 'home':
      default:
        return (
          <>
            <div className="w-full flex-grow flex flex-col">
              <div className="w-full flex justify-between items-start mb-2 space-x-2 px-2 sm:px-4">
                <div className="w-1/2">
                  <MoodProgressBar 
                    mood={mood}
                    secondsUntilDecay={secondsUntilDecay}
                    isInteracting={isInteracting}
                    interactionCount={interactionCount}
                    isDarkMode={isDarkMode}
                    key={interactionCount}
                  />
                </div>
                <div className="w-1/2">
                  <EnergyJar 
                    energy={energy} 
                    isDarkMode={isDarkMode} 
                    key={energy}
                  />
                </div>
              </div>
              
              <div className="flex-grow flex items-center justify-center mb-6 sm:mb-6">
                <div className="w-full aspect-square mx-auto rounded-lg overflow-visible relative">
                  <Character 
                    mood={mood} 
                    isAnimated={true}
                    onTap={() => handleInteraction('encourage')}
                    isInteracting={isInteracting}
                    isDarkMode={isDarkMode}
                  />
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  const theme = isDarkMode ? themes.dark : themes.light;

  return (
    <App theme={isDarkMode ? 'dark' : 'light'}>
      <Page className={`flex items-center justify-center h-full w-full ${theme.background}`}>
        <div className={`w-full h-full flex flex-col mobile-container relative ${theme.containerBg}`}>
          <div className="flex-grow overflow-y-auto overflow-x-hidden p-2 pb-16">
            <Header 
              points={points} 
              level={level} 
              isDarkMode={isDarkMode} 
              telegramUser={telegramUser}
            />
            
            {renderActivePage()}
          </div>
          
          <Footer 
            isDarkMode={isDarkMode} 
            activePage={activePage} 
            onPageChange={setActivePage}
          />
        </div>
      </Page>
    </App>
  );
}


export default MyApp;