import React from 'react';

const EnergyJar = ({ energy }) => {
  const maxEnergy = 500;
  const percentage = Math.max(0, Math.min(100, (energy / maxEnergy) * 100));

  return (
    <div className="w-full bg-[#4291b4] rounded-[9px] p-2 sm:p-4">
      <div className="flex-col w-full sm:w-40 justify-center relative top-0.5 left-0 sm:left-1.5 flex items-center">
        <div className="relative w-fit mt-[-1.00px] font-saira font-semibold text-[#edefec] text-base sm:text-lg text-right tracking-[0.36px] leading-tight">
          ENERGY
        </div>
        <div className="relative w-full sm:w-[154px] h-[13.23px]">
          <div className="h-[13px] bg-[#73e6f0] rounded-[2.41px] backdrop-blur-[185.28px] backdrop-brightness-[100%] [-webkit-backdrop-filter:blur(185.28px)_brightness(100%)]">
            <div className="relative w-full sm:w-[132px] h-[13px]">
              <div 
                className="absolute top-0 left-0 h-full transition-all duration-500 ease-in-out"
                style={{ 
                  width: `${percentage}%`,
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/energy.png)`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="relative w-full sm:w-[154px] h-4 font-saira font-semibold text-[#edefec] text-xs text-center tracking-[0.28px] leading-tight truncate">
          {Math.round(energy)}/{maxEnergy}
        </div>
      </div>
    </div>
  );
};

export default EnergyJar;