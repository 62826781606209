import React from 'react';

const MoodProgressBar = ({ mood, secondsUntilDecay, isInteracting, interactionCount }) => {
  const totalTaps = 30;
  const tapsPerMood = 10;

  const getMoodProgress = () => {
    return Math.min(100, (interactionCount / totalTaps) * 100);
  };

  const filledProgress = getMoodProgress();

  const tapsUntilNextMood = (() => {
    if (mood === 'sad') return tapsPerMood - (interactionCount % tapsPerMood);
    if (mood === 'neutral') return 2 * tapsPerMood - (interactionCount % (2 * tapsPerMood));
    return totalTaps - interactionCount;
  })();

  const getStatusText = () => {
    if (isInteracting) {
      if (tapsUntilNextMood > 0) {
        return `${tapsUntilNextMood} TAPS TO ${mood === 'sad' ? 'NEUTRAL' : mood === 'neutral' ? 'HAPPY' : 'MAX'}`;
      }
      return 'MAX MOOD!';
    }
    return secondsUntilDecay > 0 ? `DECAYS IN ${secondsUntilDecay}S` : 'DECAYING';
  };

 
  return (
    <div className="w-full bg-[#4291b4] rounded-[9px] p-2 sm:p-4">
      <div className="flex-col w-full sm:w-40 justify-center relative top-0.5 left-0 sm:left-1.5 flex items-center">
        <div className="relative w-fit mt-[-1.00px] font-saira font-semibold text-[#edefec] text-base sm:text-lg text-right tracking-[0.36px] leading-tight">
          {mood.toUpperCase()}
        </div>
        <div className="relative w-full sm:w-[154px] h-[13.23px]">
          <div className="h-[13px] bg-[#73e6f0] rounded-[2.41px] backdrop-blur-[185.28px] backdrop-brightness-[100%] [-webkit-backdrop-filter:blur(185.28px)_brightness(100%)]">
            <div className="relative w-full sm:w-[132px] h-[13px]">
              <div 
                className="absolute top-0 left-0 h-full transition-all duration-500 ease-in-out"
                style={{ 
                  width: `${filledProgress}%`,
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/energy.png)`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                }}
              ></div>
              <div className="w-full sm:w-28 h-[13px] gap-[27.67px] absolute top-0 left-0 sm:left-5 flex items-center justify-between sm:justify-start">
                {[...Array(5)].map((_, index) => (
                  <div key={index} className="relative w-[1.2px] h-[13.23px] bg-[#4291b4]" />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full sm:w-[154px] h-4 font-saira font-semibold text-[#edefec] text-xs text-center tracking-[0.28px] leading-tight truncate">
          {getStatusText()}
        </div>
      </div>
    </div>
  );
};

export default MoodProgressBar;