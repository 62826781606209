import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { logError, logToTelegram } from '../utils/errorLogger';

class CharacterErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logError(error);
    logToTelegram('error', `Character component error: ${error.message}\nStack: ${error.stack}`);
  }

  render() {
    if (this.state.hasError) {
      return <div>Character failed to load</div>;
    }

    return this.props.children;
  }
}

const Character = ({ mood, onTap, isDarkMode, isInteracting }) => {
  // CHANGE: Default animation format is now 'webm'
  const [animationFormat, setAnimationFormat] = useState('webm');
  const [currentFrame, setCurrentFrame] = useState(0);
  const [pngFrames, setPngFrames] = useState({});
  const [displayedMood, setDisplayedMood] = useState(mood);
  const [showLoader, setShowLoader] = useState(true);
  const [firstFramesLoaded, setFirstFramesLoaded] = useState(false);
  const videoRef = useRef(null);
  const animationRef = useRef(null);
  const lastFrameTime = useRef(0);
  const workerRef = useRef(null);

  const moodAnimations = useMemo(() => ({
    sad: {
      webm: '/assets/sad.webm',
      frameCount: 187,
    },
    neutral: {
      webm: '/assets/neutral.webm',
      frameCount: 176,
    },
    happy: {
      webm: '/assets/happy.webm',
      frameCount: 168,
    }
  }), []);

  const pngFps = 30;

  const isIOSOrSafariOrMac = useMemo(() => {
    const ua = navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(ua);
    const isSafari = /safari/.test(ua) && !/chrome/.test(ua);
    const isMac = /macintosh/.test(ua);
    const isChrome = /chrome/.test(ua);
    return isIOS || isSafari || (isMac && !isChrome);
  }, []);

  // CHANGE: Set animation format based on device/browser
  useEffect(() => {
    if (isIOSOrSafariOrMac) {
      setAnimationFormat('png');
    } else {
      setAnimationFormat('webm');
    }
  }, [isIOSOrSafariOrMac]);

  // CHANGE: Only load first frames if animation format is 'png'
  const loadFirstFrames = useCallback(async () => {
    if (animationFormat !== 'png') return;

    const moods = ['sad', 'neutral', 'happy'];
    const firstFrames = {};

    for (const moodToLoad of moods) {
      try {
        const firstFrameUrl = `/assets/${moodToLoad}/001.png`;
        const img = new Image();
        img.src = firstFrameUrl;
        await img.decode();
        firstFrames[moodToLoad] = [firstFrameUrl];
      } catch (error) {
        console.error(`Failed to load first frame for ${moodToLoad}:`, error);
        logToTelegram('error', `Failed to load first frame for ${moodToLoad}: ${error.message}`);
      }
    }

    setPngFrames(firstFrames);
    setFirstFramesLoaded(true);
    setShowLoader(false);
    setDisplayedMood(mood);
    setCurrentFrame(0);
  }, [mood, animationFormat]);

  // CHANGE: Handle both WebM and PNG initial loading
  useEffect(() => {
    if (animationFormat === 'png' && !firstFramesLoaded) {
      loadFirstFrames();
    } else if (animationFormat === 'webm') {
      setShowLoader(false);
    }
  }, [animationFormat, firstFramesLoaded, loadFirstFrames]);

  // CHANGE: Only load remaining frames if animation format is 'png'
  const loadRemainingFrames = useCallback((moodToLoad) => {
    if (animationFormat !== 'png') return;

    if (!workerRef.current) {
      workerRef.current = new Worker(new URL('./pngLoaderWorker.js', import.meta.url));
      workerRef.current.onmessage = (event) => {
        const { mood: loadedMood, frames } = event.data;
        setPngFrames(prevFrames => ({
          ...prevFrames,
          [loadedMood]: [...(prevFrames[loadedMood] || []), ...frames]
        }));
      };
    }

    workerRef.current.postMessage({
      mood: moodToLoad,
      frameCount: moodAnimations[moodToLoad].frameCount,
      basePath: `/assets/${moodToLoad}/`,
      loadedFrames: 1,
      fileNameFormat: '###.png'
    });
  }, [moodAnimations, animationFormat]);

  // CHANGE: Handle mood changes for both WebM and PNG
  useEffect(() => {
    setDisplayedMood(mood);
    if (animationFormat === 'png' && firstFramesLoaded) {
      loadRemainingFrames(mood);
    }
  }, [mood, animationFormat, firstFramesLoaded, loadRemainingFrames]);

  // CHANGE: Handle both WebM and PNG animation playback
  useEffect(() => {
    if (animationFormat === 'webm') {
      const playAnimation = async () => {
        if (videoRef.current) {
          try {
            await videoRef.current.play();
          } catch (error) {
            console.error('WebM playback failed:', error);
            setAnimationFormat('png');
            loadFirstFrames();
          }
        }
      };
      playAnimation();
    } else if (animationFormat === 'png' && pngFrames[displayedMood]?.length > 0) {
      const frameDuration = 1000 / pngFps;
      let frameIndex = 0;

      const animate = (timestamp) => {
        if (timestamp - lastFrameTime.current >= frameDuration) {
          setCurrentFrame(frameIndex);
          frameIndex = (frameIndex + 1) % pngFrames[displayedMood].length;
          lastFrameTime.current = timestamp;
        }
        animationRef.current = requestAnimationFrame(animate);
      };

      lastFrameTime.current = performance.now();
      animationRef.current = requestAnimationFrame(animate);

      return () => {
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }
      };
    }
  }, [displayedMood, animationFormat, pngFrames, pngFps, loadFirstFrames]);

  // CHANGE: Updated error handling for WebM
  const handleWebmError = (error) => {
    console.error('WebM loading failed:', error);
    logToTelegram('error', `WebM loading failed for mood: ${mood}`);
    setAnimationFormat('png');
    loadFirstFrames();
  };

  const handleTap = () => {
    try {
      onTap();
    } catch (error) {
      logError(error);
      logToTelegram('error', `Error in handleTap: ${error.message}`);
    }
  };

  const getContrastBrightness = () => {
    return isDarkMode 
      ? 'brightness-110 contrast-110'
      : 'brightness-105 contrast-105';
  };

  const renderLoader = () => (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
    </div>
  );

  const renderCharacter = () => {
    if (animationFormat === 'webm') {
      return (
        <video
          ref={videoRef}
          src={moodAnimations[displayedMood].webm}
          loop
          muted
          playsInline
          autoPlay
          className={`w-full h-full object-contain ${getContrastBrightness()} scale-150 video-mask character-element`}
          onError={handleWebmError}
        />
      );
    } else if (pngFrames[displayedMood]?.length > 0) {
      return (
        <img
          src={pngFrames[displayedMood][currentFrame]}
          alt={`${displayedMood} character`}
          className={`w-full h-full object-contain ${getContrastBrightness()} scale-150 character-element`}
          style={{ mixBlendMode: 'normal' }}
        />
      );
    }
    return null;
  };

  try {
    return (
      <div className="relative w-full h-full">
        <div 
          className="w-full h-full flex flex-col items-center justify-center cursor-pointer character-container"
          onClick={handleTap}
          style={{ maxHeight: 'calc(100vh - 80px)', zIndex: 1 }} 
        >
          <div className={`flex-grow flex items-center justify-center ${isInteracting ? 'interacting' : ''}`}>
            {showLoader ? renderLoader() : renderCharacter()}
          </div>
        </div>
        <div className="character-shadow-container">
          <img 
            src="/assets/shad.png" 
            alt="Character Shadow" 
            className="character-shadow bleu-shadow"
            style={{
              width: '100vw',
              maxWidth: 'none',
              height: 'auto',
              opacity: 0.2,
              filter: 'blur(10px) brightness(0) saturate(100%) hue-rotate(180deg)',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%) translateY(20px)',
            }}
          />
        </div>
      </div>
    );
  } catch (error) {
    logError(error);
    logToTelegram('error', `Error in Character render: ${error.message}`);
    return <div>Error rendering character</div>;
  }
};

const CharacterWithErrorBoundary = (props) => (
  <CharacterErrorBoundary>
    <Character {...props} />
  </CharacterErrorBoundary>
);

export default CharacterWithErrorBoundary;