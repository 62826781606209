import React from 'react';
import { StarIcon } from '@heroicons/react/24/solid';

const Header = ({ points, isDarkMode, telegramUser }) => {
  const getInitials = (name) => {
    return name ? name.charAt(0).toUpperCase() : 'U';
  };

  const getWidth = (points) => {
    const digits = points.toString().length;
    if (digits <= 3) return 'w-[85px]';
    if (digits <= 4) return 'w-[95px]';
    if (digits <= 5) return 'w-[105px]';
    return 'w-[115px]';
  };

  const calculateLevel = (points) => {
    return Math.floor(points / 10000) + 1;
  };

  const level = calculateLevel(points);

  return (
    <div className="flex justify-between items-center mb-4 px-4 pt-4">
      <div className="w-[105px] h-[42px] bg-[#4391b4] rounded-[10px] relative overflow-hidden shadow-md flex items-center">
        {telegramUser && telegramUser.photo_url ? (
          <img 
            className="w-[31px] h-[31px] ml-2 object-cover rounded-full"
            alt="User"
            src={telegramUser.photo_url}
          />
        ) : (
          <div className="w-[31px] h-[31px] ml-2 bg-[#3a7ca5] rounded-full flex items-center justify-center text-[#ddf0ff] font-saira text-[16px] font-semibold">
            {getInitials(telegramUser?.first_name || telegramUser?.username)}
          </div>
        )}
        <div className="ml-2 flex flex-col">
          <div className="text-[#ddf0ff] font-saira text-[16px] font-semibold leading-[19px] truncate max-w-[60px]">
            {telegramUser?.first_name || telegramUser?.username || 'USER'}
          </div>
          <div className="text-[#ddf0ff] font-saira text-[12px] font-semibold tracking-[0.24px] leading-[11px]">
            level-{level}
          </div>
        </div>
      </div>
      <div className={`bg-[#4391b4] rounded-[10px] h-[42px] ${getWidth(points)} relative flex items-center shadow-md`}>
        <div className="absolute left-[7px] top-[6px]">
          <StarIcon className="w-[30px] h-[30px] text-yellow-400" />
        </div>
        <span className="text-[#ddf0ff] font-saira text-[18px] font-semibold tracking-[0.36px] leading-[17px] text-right whitespace-nowrap ml-[42px] mr-2 flex-grow">
          {points.toLocaleString()}
        </span>
      </div>
    </div>
  );
};

export default Header;